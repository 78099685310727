<template>
  <div class="container">
    <v-select
      label="Supervisor"
      placeholder="Choose a supervisor"
      :items="supervisors"
      v-model="supervisor"
      item-text="name"
      item-value="id"
      outlined
      return-object
      @change="fetchReportsBySupervisor(supervisor)"
    />

    <div v-if="supervisor">
      <div v-for="(p, index) in parameters" :key="index">
        <div class="d-flex justify-space-between">
          <legend>Report {{ index + 1 }}</legend>
          <v-btn icon @click="removeReport(index)">
            <v-icon> mdi-delete</v-icon></v-btn
          >
        </div>
        <div>
          <v-text-field v-model="p.name" outlined label="Report Name" />
          <v-select
            class="mt-2"
            v-model="p.type"
            :items="worksheetTypeDefinitions"
            item-text="worksheetType"
            item-value="id"
            label="Filter by Worksheet Type"
            placeholder="All Worksheet Types"
            clearable
            outlined
            return-object
          />
        </div>
        <div v-if="p.type && p.type.hasProcessType && processTypes">
          <v-select
            clearable
            outlined
            label="Manufactoring Process Types"
            v-model="p.processTypeId"
            :items="processTypes"
            item-text="name"
            item-value="id"
          />
        </div>
      </div>

      <v-btn @click="addReport"> Add Report </v-btn>

      <v-divider />

      <div class="text-center">
        <v-btn color="primary" @click="save()"> Save </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data: () => ({
    supervisors: [],
    supervisor: null,
    parameters: [
      {
        id: null,
        paramId: null,
        name: null,
        type: null,
        typeId: null,
        processTypeId: null,
      },
    ],
  }),
  computed: {
    ...mapGetters("worksheetTypeDefinition", ["worksheetTypeDefinitions"]),
    ...mapGetters("processes", ["processTypes"]),
  },
  created() {
    this.fetchWorksheetTypeDefinitions();
    this.loadProcessTypes();
    this.fetchSupervisors();
  },
  methods: {
    ...mapActions("messages", [
      "addErrorMessage",
      "addMessage",
      "addSuccessMessage",
    ]),
    ...mapActions("worksheetTypeDefinition", ["fetchWorksheetTypeDefinitions"]),
    ...mapActions("processes", ["loadProcessTypes"]),
    ...mapActions("pageParameters", [
      "deletePageParameters",
      "createPageParameters",
      "updatePageParameters",
    ]),
    fetchSupervisors() {
      this.$axios.get("/supervisorWorksheetView/supervisors").then((resp) => {
        this.supervisors = resp.data;
        this.supervisors.forEach(
          (s) => (s.name = `${s.firstName} ${s.lastName}`)
        );
      });
    },
    fetchReportsBySupervisor(supervisor) {
      if (supervisor && supervisor.id) {
        const params = {
          page: 0,
          size: 1000,
          supervisorId: supervisor.id,
        };
        this.$axios.get("/supervisorWorksheetView", { params }).then((resp) => {
          this.parameters = resp.data.content.map((r) => {
            const paramJson = JSON.parse(r.pageParams.parameters);
            const typeId = paramJson.typeId;
            const processTypeId = paramJson.processTypeId;
            const type = this.worksheetTypeDefinitions.find(
              (t) => t.id == typeId
            );
            return {
              id: r.id,
              paramId: r.pageParams.id,
              name: r.pageParams.name,
              type,
              typeId,
              processTypeId,
            };
          });
        });
      }
    },
    removeReport(index) {
      const p = this.parameters[index];

      if (p && p.id) {
        this.$axios.delete("/supervisorWorksheetView/" + p.id).then(() => {
          if(p.paramId)
          this.deletePageParameters(p.paramId)
            .then( () => {
              this.parameters.splice(index, 1);
            })
        });
      } else {
        this.parameters.splice(index, 1);
      }
    },
    addReport() {
      this.parameters.push({
        type: null,
        typeId: null,
        processTypeId: null,
      });
    },
    async save() {
      const savedReportParamIds = [];
      for (var i = 0; i < this.parameters.length; i++) {
        const p = this.parameters[i];
        const savedP = await this.saveParams(p);
        if (savedP) {
          savedReportParamIds.push(savedP.id);
        }
      }

      //clear existing view from database;
      await this.clearSupervisorView();

      //assign saved parameters to supervisor
      const saved = [];
      for (var i = 0; i < savedReportParamIds.length; i++) {
        const pageParamId = savedReportParamIds[i];
        const data = {
          supervisorId: this.supervisor.id,
          paramId: pageParamId,
        };
        const supervisorView = await this.$axios
          .post("/supervisorWorksheetView", data)
          .then((resp) => {
            return resp.data;
          });

        saved.push(supervisorView);
      }

      if (saved.length == savedReportParamIds.length) {
        this.addSuccessMessage("Saved...");
      }
    },

    async clearSupervisorView() {
      const viewIds = this.parameters
        .filter((p) => p.id != null)
        .map((p) => p.id);

      for (var i = 0; i < viewIds.length; i++) {
        const id = viewIds[i];
        await this.$axios.delete("/supervisorWorksheetView/" + id);
      }
    },
    async saveParams(p) {
      const parameters = {
        typeId: p.type ? p.type.id : null,
        processTypeId: p.processTypeId,
      };

      const data = {
        name: p.name ? p.name : "No Name",
        page_name: "Settlement",
        vuex_name: null,
        parameters: JSON.stringify(parameters),
      };

      if (p.paramId) {
        data["id"] = p.paramId;
        return await this.updatePageParameters(data)
          .then((response) => {
            return response;
          })
          .catch(() => {
            this.addErrorMessage("Error saving report, please try again later");
          });
      } else {
        return await this.createPageParameters(data)
          .then((response) => {
            return response;
          })
          .catch(() => {
            this.addErrorMessage("Error saving report, please try again later");
          });
      }
    },
  },
};
</script>

<style>
</style>